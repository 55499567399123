import { loadScript, PayPalNamespace } from '@paypal/paypal-js';
import { PayPalScriptOptions } from '@paypal/paypal-js/types/script-options';

export default async ({ $config }: { $config: any }, inject: (key: string, value: any) => void) => {
  const options: PayPalScriptOptions = {
    clientId: $config.paypalClientId, // Replace with your PayPal Client ID
    currency: 'USD', // Optional, set the default currency
  };

  try {
    const paypal: PayPalNamespace | null = await loadScript(options);
    if (paypal) {
      inject('paypal', paypal);
    } else {
      console.error('PayPal SDK could not be loaded.');
    }
  } catch (error) {
    console.error('Error loading PayPal SDK:', error);
  }
};